
export const gymData = [
    {
        "id": 1,
        "name": "Stone Summit Atlanta",
        "creatdAt": Date.now().toString,
        "createdBy": Date.now().toString,
        "updated at": Date.now().toString,
        "updated by ": Date.now().toString,
    },
    {
        "id": 2,
        "name": "Stone Summit Atlabta Bouldering",
        "creatdAt": Date.now().toString,
        "createdBy": Date.now().toString,
        "updated at": Date.now().toString,
        "updated by ": Date.now().toString,
    },
    {
        "id": 3,
        "name": "Stone Summit Kennessaw",
        "creatdAt": Date.now().toString,
        "createdBy": Date.now().toString,
        "updated at": Date.now().toString,
        "updated by ": Date.now().toString,
    },
    {
        "id": 4,
        "name": "Stone Summit Midtown",
        "creatdAt": Date.now().toString,
        "createdBy": Date.now().toString,
        "updated at": Date.now().toString,
        "updated by ": Date.now().toString,
    },
]