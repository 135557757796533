import { Directions } from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function LoadingScreen({text}) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems:'center',
            minHeight: '100vh'

        }}>
            <p>{text}</p>
            <CircularProgress 
                size='10rem'
                thickness={3}  
            />
        </Box>
    )
}