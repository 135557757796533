import React from "react";
import Navbar from "../Navbar/Navbar";
import Login from "../Login/Login";
import ListGyms from "../ListGyms/ListGyms";
import { Outlet } from "react-router-dom";

export default function HomePage() {
    return (
        <>
                <Navbar />
                <Outlet />
        </>
    )
}