import React from "react";
import { useQuery } from "@tanstack/react-query";
import useUserInfo from "../../util/useUserInfo";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

export default function ListAttempts() {

    const { gymid, routeid } = useParams();

    const userInfo = useUserInfo();

    const { isLoading, error, data, refetch} = useQuery({
        queryKey: ['attempts',gymid,routeid],
        queryFn: () => {
            return fetch(`https://dev-api.ackme-climbing.app/v1/gyms/${gymid}/routes/${routeid}/attempts?` + new URLSearchParams({
                email: userInfo.User.email
                }),
                {
                headers: {
                    Authorization: "Bearer " + userInfo.AccessToken
                }
            })
            .then(res => {
                console.log(res)
                return res.json()
        })
        }
    })

    // sentiment
    // numberOfTakes
    // createdAt

    return (
        <>
            <h1>Ticks</h1>
            {data && data.map((attempt, key) => {
                    return (
                        <>
                            
                            <Paper elevation={3} >
                                <Stack direction='row' spacing={4}>
                                    <p>{attempt.createdAt}</p>
                                    <p>Sentiment:{attempt.sentiment}</p>
                                    <p>Takes: {attempt.numberOfTakes}</p>
                                </Stack>
                            </Paper>
                        </>
                    )
                })
            }
            
        </>
    )
}