import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import useUserInfo from "../../util/useUserInfo";
import { useMutation } from "@tanstack/react-query";


export default function AddAttempt({
    isOpen,
    id,
    handleClose
}) {

    const userInfo = useUserInfo();

    const {gymid} = useParams();

    const { register, handleSubmit, reset} = useForm();

    const postAttempt = async (data) => {
        const resp = await fetch("https://dev-api.ackme-climbing.app/v1/gyms/" + gymid + "/routes/" + id + "/attempts",
        {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Authorization: "Bearer " + userInfo.AccessToken
            }
        })

        if (!resp.ok) {
            throw new Error("bad stuff")
        } else {
            reset()
            return resp.json()
        }
    }

    const attemptMutation = useMutation({mutationFn: postAttempt})

    const onSubmit = (data) => {

        var req = {}

        req['numberOfTakes'] = parseInt(data['numberOfTakes'])
        req['sentiment'] = parseInt(data['sentiment'])
        req['id'] = id

        attemptMutation.mutate(req)
        handleClose()
    }

    return (
        <>
            <Dialog 
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit)
                  }}
            > 
                    <DialogTitle>Create a New Climb</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1} >

                            <Grid item xs={12}>
                                <p>Number of Takes</p>
                                <TextField fullWidth {...register("numberOfTakes")} type="number" />
                            </Grid>
                            <Grid item xs={12}>
                                <p>Sentiment</p>
                                <TextField fullWidth {...register("sentiment")} 
                                    type="number" 
                                    InputProps={{ inputProps: { min: "1", max: "5", step: "1" } }}    
                                />

                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="contained" 
                            fullWidth={true}
                            type='submit'
                            >
                            Submit
                        </Button>
                    </DialogActions>
                


            </Dialog>
        </>
    )
}