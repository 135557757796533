import React from "react";
import useUserInfo from "../../util/useUserInfo";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import { BorderAll } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import AddRoute from "../AddRoute/AddRoute";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Stack from "@mui/material/Stack";
import DeleteIcon from '@mui/icons-material/Delete';
import AddAttempt from "../AddAttempt/AddAttempt";
import InfoIcon from '@mui/icons-material/Info';

export default function ListRoutes() {

    const { gymid } = useParams();

    const userInfo = useUserInfo();

    const [isAddRoutesOpen, setIsAddRoutesOpen] = useState(false);
    const [isAddAttemptOpen, setIsAddAttemptOpen] = useState(false);
    const [attemptId, setAttemptId] = useState();

    const openAddAttemptDialog = (id) => {
        setAttemptId(id)
        setIsAddAttemptOpen(true)

    }

    const handleAddRouteDialogClose = () => {
        setAttemptId()
        setIsAddRoutesOpen(false)
    }

    const handleAddAttemptDialogClose = () => {
        setIsAddAttemptOpen(false)
    }

    const { isLoading, error, data, refetch} = useQuery({
        queryKey: ['routes',gymid],
        queryFn: () => {
            return fetch(`https://dev-api.ackme-climbing.app/v1/gyms/${gymid}/routes`,{
                headers: {
                    Authorization: "Bearer " + userInfo.AccessToken
                }
            })
            .then(res => {
                console.log(res)
                return res.json()
        })
        }
    })

    const deleteRoute = useMutation({mutationFn:(routeId) => {
        
        const deleteandUpdate = async (routeId) => {
            console.log("deleting")
            await fetch("https://dev-api.ackme-climbing.app/v1/gyms/" + gymid + "/routes/" + routeId ,{
                headers: {
                    Authorization: "Bearer " + userInfo.AccessToken
                },
                method: "DELETE"
            })
            refetch()
        }

        return deleteandUpdate(routeId)
    }})
    return (
        <>
            <Stack direction="row">
                <Button variant="contained" onClick={() => {setIsAddRoutesOpen(true)}}>
                    Add Route
                </Button>
                <Button onClick={refetch}>Refetch</Button>
            </Stack>
            <h1>routes</h1>
            {data && data.map((route) => {
                return (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <strong>{route.name}</strong> 
                            <em> {route.grade} </em>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction="column" spacing={1}>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.</p>
                                <Stack direction='row' spacing={1}>
                                    {
                                        route.validClimbingStyles && route.validClimbingStyles.map((style) => {
                                            return <Chip label={style} />
                                        })
                                    }
                                </Stack>
                                <Stack direction="row">

                                <Button variant="contained" fullWidth
                                    onClick={() => openAddAttemptDialog(route.id)} >
                                    Tick
                                </Button>
                                <Link to={'routes/' + route.id}>
                                    <IconButton color="standard">
                                        <InfoIcon />
                                    </IconButton>
                                </Link>
                                </Stack>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                )
            })}

            <AddRoute 
                handleClose={handleAddRouteDialogClose}
                isOpen={isAddRoutesOpen}
                refetch={refetch}
            />

            <AddAttempt
                isOpen={isAddAttemptOpen}
                id={attemptId}
                handleClose={handleAddAttemptDialogClose}
            />
        </>
    )
}