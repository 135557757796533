import React from "react";
import { AppBar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import LogoutButton from "../LogoutButton/LogoutButton";
import './Navbar.css';
import { Link } from "react-router-dom";

export default function Navbar() {
    return (
                <AppBar position="static" >
                    <Box className="navbar">
                        <Link to="/">
                            <Typography variant="h4" className="Title">
                                Ackme
                            </Typography>
                        </Link>
                        <LogoutButton />
                    </Box>
                </AppBar>
        
    )
}