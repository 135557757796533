import './App.css';
import Login from './components/Login/Login';
import useUserInfo from './util/useUserInfo';
import LogoutButton from './components/LogoutButton/LogoutButton';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Navbar from './components/Navbar/Navbar';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import ListGyms from './components/ListGyms/ListGyms';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import { gymData } from './util/testGymData';
import HomePage from './components/HomePage/HomePage';
import ErrorPage from './components/ErrorPage/ErrorPage';
import ListRoutes from './components/ListRoutes/ListRoutes';
import AddRoute from './components/AddRoute/AddRoute';
import ListAttempts from './components/ListAttempts/ListAttempts';


const router = createBrowserRouter([
  {
    path:"/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <ListGyms />
      },
      {
        path:"gyms/:gymid",
        element: <ListRoutes />
      },
      {
        path:"gyms/:gymid/add-route",
        element: <AddRoute />
      },
      {
        path:"gyms/:gymid/routes/:routeid",
        element: <ListAttempts />
      }
    ]
  },
  
])

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#2e3828',
    },
    secondary: {
      main: '#d64933',
    },
    background: {
      default: '#ede7e2',
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Login>
          <RouterProvider router={router} />
        </Login>
      
    </ThemeProvider>
  );
}

{/* <Login>
        <div className="App">
          <header className="App-header">
            <Navbar />
          </header>
          <body>
          <p>
              Welcome to Ackme Climbing
          </p>
          <ListGyms gymsData={gymData} />

          </body>
        </div>
      </Login> */}



// const Login = () => {
//   const { loginWithRedirect,getAccessTokenSilently,isAuthenticated,isLoading } = useAuth0();

//   const [authToken, setAuthToken] = useState('');

//   useEffect(() => {
//       if(isAuthenticated) {
//         console.log("we got auth?")
//         getAccessTokenSilently().then((res) => {
//           console.log("Finished getting access token")
//           console.log(res);
//           setAuthToken(res);
//         })
//       }
//     },[isAuthenticated]
//   )



//   const login = () => {
    
//     console.log("logging in")
//     loginWithRedirect()
//   }

//   if(isAuthenticated) {
//     return (
//       <>
//         <p>You are logged in</p>
//         <p>Your auth token is {authToken}</p>
//       </>
//     )
//   } else {
//     return <button onClick={()=>login()}>Log In</button>
//   }

  
// }

export default App;
