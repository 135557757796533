import React, { useEffect, useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { createContext, useContext } from "react";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

export const UserContext = createContext(null)


export default function Login({ children }) {
    const {
        loginWithRedirect,
        getAccessTokenSilently, 
        getIdTokenClaims,
        isLoading, 
        isAuthenticated, user} = useAuth0()
    
    const [accessToken, setAccessToken] = useState('');
    const [idToken, setIdToken] = useState('');

    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        if (isAuthenticated) {
            console.log("logging in")
            const fetchUserInfo = async () => {
                const accessToken = await getAccessTokenSilently()

                const idToken = await getIdTokenClaims()

                const userInfo = {
                    'AccessToken': accessToken,
                    'IdToken': idToken,
                    'User': user
                }

                setUserInfo(userInfo)
            }
   
            fetchUserInfo().catch((error) => {
                console.log(error)
            })
        }

    },[isAuthenticated])

    if (isAuthenticated) {
        // console.log("we're authenticated")
        if (Object.keys(userInfo).length != 0) {
            return (
                <>
                    <UserContext.Provider value={userInfo}>
                    {children}
                    </UserContext.Provider>
                </>
            )

        } else {
            return (
                <>
                    <LoadingScreen text="fetching user info" />
                </>
            )
            
        }
        
    } else if (isLoading) {
        return (
            <>
                <LoadingScreen text="logging in"/>
            </>
        )
    } else {
        loginWithRedirect()
    }
    
}