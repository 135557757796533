import React from "react";
import { Box, Button } from "@mui/material";
import Card from "@mui/material/Card";
import './ListGyms.css';
import { useQuery } from "@tanstack/react-query";
import useUserInfo from "../../util/useUserInfo";
import { Link } from "react-router-dom";

export default function ListGyms() {

    const userInfo = useUserInfo();

    const { isLoading, error, data} = useQuery({
        queryKey: ['gyms'],
        queryFn: () => {
            return fetch("https://dev-api.ackme-climbing.app/v1/gyms",{
                headers: {
                    Authorization: "Bearer " + userInfo.AccessToken
                }
            })
            .then(res => res.json())
        }
    })

    if (isLoading) return 'Loading'

    if (error) return 'An error has occured'

    var gymsData = []
    gymsData = data;

    console.log(data);

    return (
        <>
            <h1>Gyms Near You</h1>
            <Box className="gymTable">
                { gymsData && gymsData.map((gym, i) => {
                        return (
                            <Card className="GymContainer" key={i}>
                                <img className="BannerImage" src="http://placekitten.com/300/300"/>
                                <div>
                                    {gym.name}
                                </div>

                                <img className="Icon1" src="http://placekitten.com/300/300"/>
                                <img className="Icon2" src="http://placekitten.com/300/300"/>
                                <img className="Icon3" src="http://placekitten.com/300/300"/>
                                <Link to={'gyms/' + gym.id}>
                                    <Button className="navButton">Explore</Button>
                                </Link>

                            </Card>
                        )
                    })
                }
            </Box>
        </>

    )
}