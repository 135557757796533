import React from "react";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { Button, FormGroup } from "@mui/material";
import { Checkbox } from "@mui/material";
import { FormControlLabel } from "@mui/material"
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import useUserInfo from "../../util/useUserInfo";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

export default function AddRoute({
    handleClose,
    isOpen,
    refetch
}) {
    const userInfo = useUserInfo();

    const postRoute = async (data) => {
        const resp = await fetch("https://dev-api.ackme-climbing.app/v1/gyms/" + gymid + "/routes",
        {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Authorization: "Bearer " + userInfo.AccessToken
            }
        })

        if (!resp.ok) {
            throw new Error("bad stuff")
        } else {
            refetch()
            reset()
            return resp.json()
        }
    }

    const mutation = useMutation({mutationFn: postRoute})

    const { gymid } = useParams();

    const { register, handleSubmit, reset} = useForm();

    const onSubmit = (data) => {
        console.log("creating a new climb")
        var req = {}

        req['name'] = data['name']
        req['grade'] = data['grade']
        req['validClimbingStyles'] = []

        if (data['isBoulder']) req['validClimbingStyles'].push('bouldering')
        if (data['isLead']) req['validClimbingStyles'].push('lead')
        if (data['isTopRope']) req['validClimbingStyles'].push('top-rope')

        mutation.mutate(req)


        handleClose()
    }


    return (
        <>
            <Dialog 
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit)
                  }}
            > 
                    <DialogTitle>Create a New Climb</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1} >

                            <Grid item xs={12}>
                                <p>Climb Name</p>
                                <TextField fullWidth {...register("name")} />
                            </Grid>
                            <Grid item xs={12}>
                                <p>Climb Grade</p>
                                <TextField fullWidth {...register("grade")} />
                            </Grid>
                            <Grid item xs={12} >
                                <p>Climb Type</p>
                                <FormControlLabel {...register("isBoulder")} control={<Checkbox defaultChecked />} label="Boulder" />
                                <FormControlLabel {...register("isLead")} control={<Checkbox />} label="Lead" />    
                                <FormControlLabel {...register("isTopRope")} control={<Checkbox />} label="Top Rope" />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="contained" 
                            fullWidth={true}
                            type='submit'
                            >
                            Submit
                        </Button>
                    </DialogActions>
                


            </Dialog>
        </>
    )
}